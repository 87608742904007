.contacts {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0.2rem;

  .pin-text {
    padding: 0.2rem;
    padding-left: 3rem;
    color: $grey;
    font-family: $fontFamily;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin: 1rem 0;
    background: url(../img/pin.png) 0 50% no-repeat;
  }
}

#map {
  position: relative;
  &::before, &::after {
    content: " ";
    position: absolute;
    top: 0;
    height: 1rem;
    width: 50%;
    z-index: 100;
  }
  &::before {
    left: 0;
    border-right: 1rem solid transparent;
    border-top: 1rem solid white;
  }
  &::after {
    right: 0;
    border-left: 1rem solid transparent;
    border-top: 1rem solid white;
  }
}