.review-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 2rem;

  @media #{$mobile} {
    justify-content: space-around;
  }
}

.review {
  max-width: 350px;
  background-color: $greyBack;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  position: relative;

  .review__avatar {
    position: absolute;
    top: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    left: 145px;
    border: 1px solid $greyBack;
    border-radius: 50%;
  }

  .review__name {
    color: $grey;
    font-family: $fontFamilySemiBold;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .review__msg {
    margin-top: 0.7rem;
    color: $grey;
    font-family: $fontFamily;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    
    height: 101px;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: height 0.3s;
  }
  .review__more {
    text-align: center;
    color: $purpleMore;
    font-family: $fontFamilySemiBold;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 0.5rem;
    text-transform: uppercase;
    cursor: pointer;
  }
} 