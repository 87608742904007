.title {

  margin: auto;
  text-align: center;

  &.inverted {
    h1::before, h1::after {
      background-color: $white;
    }
  }
  h1 {

    position: relative;
    color: $grey;
    font-family: $fontFamilySemiBold;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
    display: inline;
    padding: 0 1rem;
    margin: 0;
    
    &::before, &::after {
      content: " ";
      position: absolute;
      top: 17px;
      max-width: 174px;
      width: 174px;
      height: 2px;
      background-color: $greenLine;

      @media #{$mobile} {
        content: none;
      }
    }

    &::before {
      right: 100%;
    }
    
    &::after {
      left: 100%;
    }
  }
}