section.section {
  div.container.school {
    max-width: 920px;
  }

  &.school {
    background-color: $greyBack;
    padding: 0 0 3.5rem;

    h2 {
      margin: 1rem 0;
      text-align: center;
      color: $grey;
      font-family: $fontFamily;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.school-description {
  color: $grey;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 2rem 0 3rem;
  @media #{$mobile} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.price-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-table__header {
  display: flex;
  .price-table__cell {
    background-color: $purple;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    font-family: $fontFamilySemiBold;
  }
}

.price-table__row {
  display: flex;
  .price-table__cell {
    background-color: $white;
    color: $grey;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.price-table__cell {
  padding: 1rem;
  margin: 1px;
  width: 228px;

  @media #{$mobile} {
    width: 75px;
    padding: 2px;
  }
}

table.price-table {
  display: table;
  margin: auto;

  @media #{$mobile} {
    margin: 2rem 0 0;
    width: 100%;
  }
}

table .price-table__header {
  display: table-row;
  .price-table__cell {
    background-color: $purple;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    font-family: $fontFamilySemiBold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

table .price-table__row {
  display: table-row;
  .price-table__cell {
    background-color: $white;
    color: $grey;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

table .price-table__cell {
  padding: 1rem;
  margin: 1px;
  width: 228px;
  vertical-align: top;

  @media #{$mobile} {
    width: 75px;
    padding: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}