.about-section {
  display: flex;

  @media #{$mobile} {
    flex-direction: column;

    .left {
      margin: auto;
    }
    .right {
      margin-left: 0;
      p {
        padding: 0.5rem;
        margin-left: 0;
        text-align: justify;
      }
    }
  }

  p {
    color: $grey;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 4rem;
  }
}