.call-form {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  background: url(../img/call.png) no-repeat; 

  &.call-purple {
    background: url(../img/call-purple.png) no-repeat; 
  }
  .phone-number {
    font-size: 20px;
    &.grey {
      color: $grey;
    }
  }

  .header-btn-call {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $greenLogo;
    cursor: pointer;
    margin-left: 1rem;
    border: none;
    background: transparent;
  }
}
.btn-call-listener:focus {
  outline: none;
}