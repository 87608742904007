section.section.section-offer {
  background-color: $greyBack;
}

.offer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-top: 1rem;

  @media #{$mobile} {
    justify-content: space-around;
  }
}

.offer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 250px;

  .offer__container {

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 125px;
    height: 125px;
    border-radius: 50%;
  }

  .offer__header {
    color: $grey;
    font-family: $fontFamilySemiBold;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    margin: 1rem 0;
  }

  .offer__description {
    margin: 0;
    width: 250px;
    color: $grey;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
  .offer__more {
    margin-top: 1rem;
    color: $purpleMore;
    font-family: $fontFamilySemiBold;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    text-transform: uppercase;
    border: none;
    background-color: inherit;
  }
}

.main-background {
  background: url(../img/background.png) no-repeat;
  background-size: cover;
  padding-bottom: 8rem;
  @media #{$mobile} {
    max-width: 100%;
    padding-bottom: 12rem;
  }
}

.info-container {
  max-width: 920px;
  margin: auto;
  position: relative;
  border-left: 2px solid $white;
  border-right: 2px solid $white;
  background-color: rgba(0,0,0, 0.25);

  @media #{$mobile} {
    max-width: 100%;
  }
  .info-container__background {
    opacity: 0.25;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .line {
    content: " ";
    position: absolute;
    height: 2px;
    background-color: $white;

    @media #{$mobile} {
      display: none;
    }

    &.line-top-left, &.line-top-right {
      top: 0;
      width: 9rem;
    }
    &.line-top-left, &.line-bottom-left {
      left: 0;
    }
    &.line-top-right, &.line-bottom-right {
      right: 0;
    }
    &.line-bottom-left, &.line-bottom-right  {
      bottom: 0;
      width: 20rem;
    }
  }

  .info-container__header {
    position: absolute;
    top: -13px;
    right: 0;
    left: 0;
    text-align: center;
    height: 29px;
    text-shadow: 0 0 20px #020202;
    color: $white;
    font-family: $fontFamilySemiBold;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 24px;

    @media #{$mobile} {
      top: 1rem;
      line-height: 2rem;
    }
  }

  .button-container {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-align: center;

    @media #{$mobile} {
      bottom: -10rem;
    }
    button {
      width: 240px;
    }
  }

  .offeritemlist {
    display: flex;
    justify-content: space-around;

    @media #{$mobile} {
      display: none;
    }
  }

  .offeritem {
    width: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 2rem;

    .offeritem__img {
      width: 90px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 1rem;
    }
    .offeritem__title {
      text-align: center;
      color: $white;
      font-family: $fontFamilySemiBold;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }
    .offeritem__subtitle {
      text-align: center;
      color: $white;
      font-family: $fontFamily;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.purple-button {
  border: none;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 0 25px rgba(2, 2, 2, 0.25), inset 0 -1px 0 1px rgba(2, 2, 2, 0.15);
  background-color: $purple;
  background-image: linear-gradient(to top, rgba(2, 2, 2, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  color: $white;
  font-family: $fontFamilySemiBold;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}