.subheader {
  background-color: $greenHeader;

  .container {
    margin: auto;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    @media #{$mobile} {
      padding: 0;
    }

    .logo {
      .logo-place {
        color: $purple;
      }
      .logo-work {
        color: $grey;
      }
    }

    ul.refs {
      justify-content: flex-end;
      li a, li button {
        margin: 0;
        margin-left: 1rem;
        color: $grey;
        
        @media #{$mobile} {
          margin: 0 0.5rem;
        }

        &:hover {
          color: $greyActive;
        }
      }
    }
  }

  &.background {
    background-color: transparent;

    .logo {
      .logo-place {
        color: $greenLogo;
      }
      .logo-work {
        color: $white;
      }
    }
    ul.refs {
      li a {
        color: $white;
      }
    }
  }
}