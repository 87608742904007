$white: #ffffff; 
$greyBack: #f2f2f2;
$grey: #333;
$greySocial: #666;
$greyActive: #999;

$purple: #883c97;
$purpleFooter: #6a2a77;
$purpleOverlay: #2a0040;
$purpleMore: #993399;

$green: #6aff00;
$greenLogo: #97ff4c;
$greenBtn: #88e645;
$greenHeader: #d2ffb2;
$greenLine: #74e622;

$borderRadius: 8px;
$bigBorderRadius: 18px;

$fontSizeSmall: 16px;
$fontSizeText: 16px;
$fontSizeTextHeader: 18px;
$fontSize: 20px;
$fongSizeHeader: 30px;

@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: MontserratSemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
  
$fontFamily: Montserrat;
$fontFamilySemiBold: MontserratSemiBold;

$break-small: 321px;
$break-middle: 768px;
$break-large: 1280px;

$mobile: "only screen and (max-width: 768px)";
$tablet: "only screen and (min-width: 769px) and (max-width: 1280px)";
$desktop: "only screen and (min-width: 1281px)";

$max-width: 900px;