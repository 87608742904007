@import "call";
@import "arenda.footer";
@import "subheader";
@import "title";

section.section {

  div.container {
    margin: auto;
    max-width: 1110px;
    padding-top: 2.75rem;
  }


}

.social-share {
  .social-share-icons.ya-share2 {

    .ya-share2 {
      width: 34px;
      height: 34px;
      margin-left: 5px;
      margin-right: 5px;
      background-color: transparent;

      &__item_service_facebook {
        margin-right: 10px;
        .ya-share2__badge {
          background-color: transparent;
        }
        .ya-share2__icon {
          background: url(../img/ico_soc-FB.png) no-repeat;
          height: 34px;
          width: 34px;
        }
      }
      &__item_service_vkontakte {
        margin-right: 10px;
        .ya-share2__badge {
          background-color: transparent;
        }
        .ya-share2__icon {
          background: url(../img/ico_soc-VK.png) no-repeat;
          height: 34px;
          width: 34px;
        }
      }
      &__item_service_twitter {
        margin-right: 10px;
        .ya-share2__badge {
          background-color: transparent;
        }
        .ya-share2__icon {
          background: url(../img/ico_soc-TW.png) no-repeat;
          height: 34px;
          width: 34px;
        }
      }
      &__item_service_gplus {
        .ya-share2__badge {
          background-color: transparent;
        }
        .ya-share2__icon {
          background: url(../img/ico_soc-G+.png) no-repeat;
          height: 34px;
          width: 34px;
        }
      }
    }
  } 
}