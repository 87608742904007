h2.h2 {
  height: 21px;
  color: $grey;
  font-family: $fontFamily;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.information {
  margin-bottom: 3rem;
  color: $grey;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  p {
    margin: 0;
  }
}

.section-arenda {
  background-color: $greyBack;
}

.download {
  display: block;
  padding-left: 2rem;
  margin: 2rem auto 3rem;
  color: $purpleMore;
  font-family: $fontFamilySemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  background: url(../img/download.png)  0 50% no-repeat;
}
section.section div.container.container-arenda {
  max-width: 920px;

  sup {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
}
.sub-message {
  margin: 0;
  margin-bottom: 2rem;
  padding: 0 2rem;
  color: $grey;
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
.grid-layout {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;


  @media #{$mobile} {
    margin-top: 4rem;
    grid-template-columns: 1fr;
  }
}
.grid-item {
  
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .img {
    width: 110px;
    min-width: 110px;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .description {
    color: $grey;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 3rem;
    margin-right: 2rem;
  }
}