.arenda-footer {
  background-color: $greenHeader;
  padding: 1.875rem 0;
}
.small-container {
  max-width: 920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @media #{$mobile} {
    flex-direction:  column;
  }
}

.call-text {
  font-size: 18px;
  color: $grey;
  line-height:  24px;
  @media #{$mobile} {
    text-align: center;
    height: 5rem;
  }
  .phone-number {
    font-size: 24px;
    font-weight: bold;
    padding-left:.7rem;
    @media #{$mobile} {
      margin-top: 1rem;
      display: block;
    }
  }
}

.btn-call {
  font-family: $fontFamily;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  color: $grey;
  padding: 1rem 2.5rem 1rem 4rem;
  box-shadow: inset 0 -1px 0 1px rgba(2, 2, 2, 0.15);
  background: linear-gradient(to top, rgba(2, 2, 2, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url(../img/call-black.png) 2rem 48% no-repeat;
  background-color: $greenBtn;
  cursor: pointer;
}