@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MontserratSemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

/*

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers 
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}*/

html {
  box-sizing: border-box;
}

* {
  font-size: inherit;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  cursor: pointer;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}

.section {
  width: 100%;
}

.v-center {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  max-width: 1100px;
  margin: auto;
}

.header {
  background: #883c97;
  height: 3.125rem;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .header {
    height: 6rem;
  }

  .header .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
  }
}

footer.section {
  background-color: #6a2a77;
  color: #ffffff;
}

footer.section .footer-contacts {
  padding: 3rem 0 8rem;
}

@media only screen and (max-width: 768px) {
  footer.section .footer-contacts {
    padding: 0 0 4rem;
    flex-direction: column;
  }

  footer.section .footer-contacts .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer.section .footer-contacts .social {
    align-items: center;
    margin-top: 1rem;
  }
}

.top-margined {
  margin-top: 1rem;
}

.top-margined-x2 {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .refs-parent {
    overflow: hidden;
  }
}

ul.refs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 40px 0;
  width: 100%;
  list-style: none;
}

@media only screen and (max-width: 768px) {
  ul.refs {
    display: block;
    padding: 1rem 0;
    flex-wrap: nowrap;
    width: auto;
    overflow-x: scroll;
    list-style-type: none;
    white-space: nowrap;
  }

  ul.refs li {
    display: inline-block;
  }

  ul.refs li a,
  ul.refs li button.btn-open-menu {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

ul.refs li a,
ul.refs li button.btn-open-menu {
  display: block;
  white-space: nowrap;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 1rem;
  font-size: 0.875rem;
  background: transparent;
  border: none;
  padding: 0;
  font-family: Montserrat;
  cursor: pointer;
}

ul.refs li a.active,
ul.refs li button.btn-open-menu.active {
  color: #999 !important;
}

ul.refs li a.hover,
ul.refs li button.btn-open-menu.hover {
  color: #999;
}

button.btn-open-menu:focus {
  color: #999 !important;
  outline: none;
}

.popup-menu-container {
  position: relative;
  z-index: 1;
}

.popup-menu-list {
  display: none;
  position: absolute;
  background-color: #f2f2f2;
  top: 2.5rem;
  left: -2rem;
}

.popup-menu-list::before,
.popup-menu-list::after {
  content: " ";
  position: absolute;
  display: block;
  width: 1rem;
  top: -1rem;
  height: 1rem;
}

.popup-menu-list::before {
  left: 50%;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #f2f2f2;
}

.popup-menu-list::after {
  right: 50%;
  border-left: 1rem solid transparent;
  border-bottom: 1rem solid #f2f2f2;
}

ul.refs li .popup-menu-container .popup-menu-list a.popup-menu-item {
  margin: 0;
  color: #883c97;
  font-family: MontserratSemiBold;
  padding: 1rem 2rem;
  display: block;
  text-align: center;
}

ul.refs li .popup-menu-container .popup-menu-list a.popup-menu-item:hover {
  color: #333;
  background-color: #ffffff;
}

.bottom-bordered {
  border-bottom: 1px solid #ffffff;
}

/* fonts */

.bold {
  font-weight: bold;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.bottom-margined {
  margin-bottom: 3.75rem;
}

a.btn-more,
button.btn-more,
.btn-more {
  width: 540px;
  max-width: 95%;
  text-align: center;
  border: 2px solid #74e622;
  background-color: #f2f2f2;
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 11px;
  cursor: pointer;
}

a.btn-more:focus,
button.btn-more:focus,
.btn-more:focus {
  color: #333;
}

.logo {
  text-transform: uppercase;
  padding-left: 4rem;
  height: 3rem;
  background: url(../img/logo.png) 0 50% no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .logo {
    display: none;
  }
}

.logo .logo-place {
  font-weight: bold;
  font-size: 20px;
  color: #97ff4c;
}

.logo .logo-work {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2.1px;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.social .social-refs {
  display: flex;
}

.social .social-refs .ref {
  width: 2.625rem;
  height: 2.625rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.social .social-refs .ref:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.social .social-refs .ref:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.social .social-refs .ref.cloud-ref {
  background: url(../img/cloud.png) 50% 50% no-repeat;
}

.social .social-refs .ref.fb-ref {
  background: url(../img/fb.png) 50% 50% no-repeat;
}

.social .social-refs .ref.vk-ref {
  background: url(../img/vk.png) 50% 50% no-repeat;
}

.social .social-refs .ref.insta-ref {
  background: url(../img/inst.png) 50% 50% no-repeat;
}

#club-list {
  max-width: 1089px;
  margin: auto;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
  #club-list {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  #club-list .club-photo {
    display: flex;
    justify-content: center;
  }
}

.slick-prev.slick-arrow,
.slick-next.slick-arrow {
  cursor: pointer;
  z-index: 100;
  content: " ";
  position: absolute;
  top: 53px;
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
}

.slick-prev.slick-arrow:focus,
.slick-next.slick-arrow:focus {
  outline: none;
}

.slick-prev.slick-arrow {
  left: -22px;
  background-image: url(../img/back.png);
}

@media only screen and (max-width: 768px) {
  .slick-prev.slick-arrow {
    left: 1rem;
  }
}

.slick-next.slick-arrow {
  right: -3px;
  background-image: url(../img/forward.png);
}

@media only screen and (max-width: 768px) {
  .slick-next.slick-arrow {
    right: 1rem;
  }
}

.search-container {
  position: relative;
}

.submit-btn-search {
  border: none;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background: url(../img/ico-search.png) 100% 0 no-repeat;
  top: 0;
  right: 0;
}

.search-input {
  width: 250px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  padding: 2px 18px 2px 0px;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.search-input::placeholder {
  color: #ffffff;
}

.search-input:focus {
  outline: none;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
  margin: auto;
  margin-top: 116px;
  width: 770px;
  padding: 40px;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .modal {
    bottom: auto;
    margin: 0;
    width: 100%;
    border-radius: 0;
    padding: 0;
    min-height: 100%;
    position: absolute;
  }

  .modal .modal-container {
    padding: 40px 1rem;
  }
}

.modal .purple-button {
  padding-left: 40px;
  padding-right: 40px;
}

.btn-modal-close {
  background: none;
  border: none;
  font-size: 3rem;
  font-family: Montserrat;
  line-height: 3rem;
  position: absolute;
  top: .2rem;
  right: .5rem;
  color: #999;
  cursor: pointer;
}

.modal-header {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 1rem;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .modal-header {
    margin-top: 1rem;
  }
}

.modal-result {
  padding: 2rem 1rem;
  margin-top: 1rem;
}

.modal-result p {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin: .5rem;
}

.modal-form {
  margin-top: 1rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
  .inputs {
    padding: 0;
  }
}

.inputs-row {
  display: flex;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .inputs-row {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.input-title {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.input {
  min-width: 248px;
  padding: 0.5rem;
  font-family: Montserrat;
  font-size: 16px;
  border: 1px solid #ccc;
}

.input-name {
  min-width: 360px;
  margin-right: 2rem;
}

@media only screen and (max-width: 768px) {
  .input-name {
    min-width: auto;
    margin-right: 0;
  }
}

select.input {
  background-color: white;
}

.call-form {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  background: url(../img/call.png) no-repeat;
}

.call-form.call-purple {
  background: url(../img/call-purple.png) no-repeat;
}

.call-form .phone-number {
  font-size: 20px;
}

.call-form .phone-number.grey {
  color: #333;
}

.call-form .header-btn-call {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #97ff4c;
  cursor: pointer;
  margin-left: 1rem;
  border: none;
  background: transparent;
}

.btn-call-listener:focus {
  outline: none;
}

.arenda-footer {
  background-color: #d2ffb2;
  padding: 1.875rem 0;
}

.small-container {
  max-width: 920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .small-container {
    flex-direction: column;
  }
}

.call-text {
  font-size: 18px;
  color: #333;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  .call-text {
    text-align: center;
    height: 5rem;
  }
}

.call-text .phone-number {
  font-size: 24px;
  font-weight: bold;
  padding-left: .7rem;
}

@media only screen and (max-width: 768px) {
  .call-text .phone-number {
    margin-top: 1rem;
    display: block;
  }
}

.btn-call {
  font-family: Montserrat;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  color: #333;
  padding: 1rem 2.5rem 1rem 4rem;
  box-shadow: inset 0 -1px 0 1px rgba(2, 2, 2, 0.15);
  background: linear-gradient(to top, rgba(2, 2, 2, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url(../img/call-black.png) 2rem 48% no-repeat;
  background-color: #88e645;
  cursor: pointer;
}

.subheader {
  background-color: #d2ffb2;
}

.subheader .container {
  margin: auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

@media only screen and (max-width: 768px) {
  .subheader .container {
    padding: 0;
  }
}

.subheader .container .logo .logo-place {
  color: #883c97;
}

.subheader .container .logo .logo-work {
  color: #333;
}

.subheader .container ul.refs {
  justify-content: flex-end;
}

.subheader .container ul.refs li a,
.subheader .container ul.refs li button {
  margin: 0;
  margin-left: 1rem;
  color: #333;
}

@media only screen and (max-width: 768px) {
  .subheader .container ul.refs li a,
  .subheader .container ul.refs li button {
    margin: 0 0.5rem;
  }
}

.subheader .container ul.refs li a:hover,
.subheader .container ul.refs li button:hover {
  color: #999;
}

.subheader.background {
  background-color: transparent;
}

.subheader.background .logo .logo-place {
  color: #97ff4c;
}

.subheader.background .logo .logo-work {
  color: #ffffff;
}

.subheader.background ul.refs li a {
  color: #ffffff;
}

.title {
  margin: auto;
  text-align: center;
}

.title.inverted h1::before,
.title.inverted h1::after {
  background-color: #ffffff;
}

.title h1 {
  position: relative;
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  display: inline;
  padding: 0 1rem;
  margin: 0;
}

.title h1::before,
.title h1::after {
  content: " ";
  position: absolute;
  top: 17px;
  max-width: 174px;
  width: 174px;
  height: 2px;
  background-color: #74e622;
}

@media only screen and (max-width: 768px) {
  .title h1::before,
  .title h1::after {
    content: none;
  }
}

.title h1::before {
  right: 100%;
}

.title h1::after {
  left: 100%;
}

section.section div.container {
  margin: auto;
  max-width: 1110px;
  padding-top: 2.75rem;
}

.social-share .social-share-icons.ya-share2 .ya-share2 {
  width: 34px;
  height: 34px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: transparent;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_facebook {
  margin-right: 10px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_facebook .ya-share2__badge {
  background-color: transparent;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_facebook .ya-share2__icon {
  background: url(../img/ico_soc-FB.png) no-repeat;
  height: 34px;
  width: 34px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_vkontakte {
  margin-right: 10px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_vkontakte .ya-share2__badge {
  background-color: transparent;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_vkontakte .ya-share2__icon {
  background: url(../img/ico_soc-VK.png) no-repeat;
  height: 34px;
  width: 34px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_twitter {
  margin-right: 10px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_twitter .ya-share2__badge {
  background-color: transparent;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_twitter .ya-share2__icon {
  background: url(../img/ico_soc-TW.png) no-repeat;
  height: 34px;
  width: 34px;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_gplus .ya-share2__badge {
  background-color: transparent;
}

.social-share .social-share-icons.ya-share2 .ya-share2__item_service_gplus .ya-share2__icon {
  background: url(../img/ico_soc-G+.png) no-repeat;
  height: 34px;
  width: 34px;
}

.team-description {
  max-width: 730px;
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 1rem auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .team-description {
    margin: 1rem .5rem;
  }
}

.coach-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.coach {
  width: 270px;
  margin-bottom: 2rem;
}

.coach__photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.coach__photo img {
  max-width: 230px;
  max-height: 230px;
  border-radius: 50%;
}

.coach__name {
  text-align: center;
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  margin: 1rem 0;
}

.coach__whos .coach__who {
  text-align: center;
  color: #999;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: .25rem 0;
}

.contacts {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.contacts .pin-text {
  padding: 0.2rem;
  padding-left: 3rem;
  color: #333;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 1rem 0;
  background: url(../img/pin.png) 0 50% no-repeat;
}

#map {
  position: relative;
}

#map::before,
#map::after {
  content: " ";
  position: absolute;
  top: 0;
  height: 1rem;
  width: 50%;
  z-index: 100;
}

#map::before {
  left: 0;
  border-right: 1rem solid transparent;
  border-top: 1rem solid white;
}

#map::after {
  right: 0;
  border-left: 1rem solid transparent;
  border-top: 1rem solid white;
}

section.section div.container.school {
  max-width: 920px;
}

section.section.school {
  background-color: #f2f2f2;
  padding: 0 0 3.5rem;
}

section.section.school h2 {
  margin: 1rem 0;
  text-align: center;
  color: #333;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.school-description {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 2rem 0 3rem;
}

@media only screen and (max-width: 768px) {
  .school-description {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.price-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-table__header {
  display: flex;
}

.price-table__header .price-table__cell {
  background-color: #883c97;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  font-family: MontserratSemiBold;
}

.price-table__row {
  display: flex;
}

.price-table__row .price-table__cell {
  background-color: #ffffff;
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.price-table__cell {
  padding: 1rem;
  margin: 1px;
  width: 228px;
}

@media only screen and (max-width: 768px) {
  .price-table__cell {
    width: 75px;
    padding: 2px;
  }
}

table.price-table {
  display: table;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  table.price-table {
    margin: 2rem 0 0;
    width: 100%;
  }
}

table .price-table__header {
  display: table-row;
}

table .price-table__header .price-table__cell {
  background-color: #883c97;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  font-family: MontserratSemiBold;
  overflow: hidden;
  text-overflow: ellipsis;
}

table .price-table__row {
  display: table-row;
}

table .price-table__row .price-table__cell {
  background-color: #ffffff;
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

table .price-table__cell {
  padding: 1rem;
  margin: 1px;
  width: 228px;
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  table .price-table__cell {
    width: 75px;
    padding: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.photo-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .photo-list {
    justify-content: space-around;
  }
}

.photo {
  max-width: 380px;
  padding-bottom: 40px;
}

.photo img {
  max-width: 100%;
}

.photo .photo__description {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 1rem 0 0.5rem;
}

.photo .photo__date {
  color: #999;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.news-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .news-list {
    justify-content: space-around;
  }
}

.news {
  max-width: 255px;
  background-color: #f2f2f2;
  margin-bottom: 2rem;
}

.news img {
  max-width: 100%;
}

.news .news__container {
  padding: 1rem;
  overflow: hidden;
  height: 12rem;
}

.news .news__description {
  color: #883c97;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0.5rem;
}

.news .news__date {
  color: #999;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.review-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .review-list {
    justify-content: space-around;
  }
}

.review {
  max-width: 350px;
  background-color: #f2f2f2;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  position: relative;
}

.review .review__avatar {
  position: absolute;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  left: 145px;
  border: 1px solid #f2f2f2;
  border-radius: 50%;
}

.review .review__name {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.review .review__msg {
  margin-top: 0.7rem;
  color: #333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  height: 101px;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: height 0.3s;
}

.review .review__more {
  text-align: center;
  color: #993399;
  font-family: MontserratSemiBold;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
}

.about-section {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .about-section .left {
    margin: auto;
  }

  .about-section .right {
    margin-left: 0;
  }

  .about-section .right p {
    padding: 0.5rem;
    margin-left: 0;
    text-align: justify;
  }
}

.about-section p {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 4rem;
}

.news-container {
  margin: auto;
  margin-bottom: 4rem;
  max-width: 920px;
}

.news-container h1.h1 {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  padding: 0 1rem;
  margin: 0 0 2rem 0;
  text-align: center;
}

.news-container .date {
  margin: 2rem auto;
  text-align: center;
  height: 13px;
  color: #999;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.news-container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .news-container .image-container img {
    max-width: 100%;
  }
}

.news-container p {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  .news-container p {
    text-align: justify;
    padding: 0 .5rem;
  }
}

.news-container .social-share {
  color: #666;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

.news-container .social-share .social-share-icons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.news-container .social-share .social-share-icons .social-share-icon {
  width: 34px;
  height: 34px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: transparent;
}

.news-container .social-share .social-share-icons .social-share-icon.fb-icon {
  background: url(../img/ico_soc-FB.png) no-repeat;
}

.news-container .social-share .social-share-icons .social-share-icon.vk-icon {
  background: url(../img/ico_soc-VK.png) no-repeat;
}

.news-container .social-share .social-share-icons .social-share-icon.tw-icon {
  background: url(../img/ico_soc-TW.png) no-repeat;
}

.news-container .social-share .social-share-icons .social-share-icon.google-icon {
  background: url(../img/ico_soc-G+.png) no-repeat;
}

h2.h2 {
  height: 21px;
  color: #333;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.information {
  margin-bottom: 3rem;
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.information p {
  margin: 0;
}

.section-arenda {
  background-color: #f2f2f2;
}

.download {
  display: block;
  padding-left: 2rem;
  margin: 2rem auto 3rem;
  color: #993399;
  font-family: MontserratSemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  background: url(../img/download.png) 0 50% no-repeat;
}

section.section div.container.container-arenda {
  max-width: 920px;
}

section.section div.container.container-arenda sup {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}

.sub-message {
  margin: 0;
  margin-bottom: 2rem;
  padding: 0 2rem;
  color: #333;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.grid-layout {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 768px) {
  .grid-layout {
    margin-top: 4rem;
    grid-template-columns: 1fr;
  }
}

.grid-item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.grid-item .img {
  width: 110px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item .description {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 3rem;
  margin-right: 2rem;
}

section.section.section-offer {
  background-color: #f2f2f2;
}

.offer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .offer-list {
    justify-content: space-around;
  }
}

.offer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 250px;
}

.offer .offer__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.offer .img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 125px;
  height: 125px;
  border-radius: 50%;
}

.offer .offer__header {
  color: #333;
  font-family: MontserratSemiBold;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  margin: 1rem 0;
}

.offer .offer__description {
  margin: 0;
  width: 250px;
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.offer .offer__more {
  margin-top: 1rem;
  color: #993399;
  font-family: MontserratSemiBold;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
  border: none;
  background-color: inherit;
}

.main-background {
  background: url(../img/background.png) no-repeat;
  background-size: cover;
  padding-bottom: 8rem;
}

@media only screen and (max-width: 768px) {
  .main-background {
    max-width: 100%;
    padding-bottom: 12rem;
  }
}

.info-container {
  max-width: 920px;
  margin: auto;
  position: relative;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  background-color: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 768px) {
  .info-container {
    max-width: 100%;
  }
}

.info-container .info-container__background {
  opacity: 0.25;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.info-container .line {
  content: " ";
  position: absolute;
  height: 2px;
  background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .info-container .line {
    display: none;
  }
}

.info-container .line.line-top-left,
.info-container .line.line-top-right {
  top: 0;
  width: 9rem;
}

.info-container .line.line-top-left,
.info-container .line.line-bottom-left {
  left: 0;
}

.info-container .line.line-top-right,
.info-container .line.line-bottom-right {
  right: 0;
}

.info-container .line.line-bottom-left,
.info-container .line.line-bottom-right {
  bottom: 0;
  width: 20rem;
}

.info-container .info-container__header {
  position: absolute;
  top: -13px;
  right: 0;
  left: 0;
  text-align: center;
  height: 29px;
  text-shadow: 0 0 20px #020202;
  color: #ffffff;
  font-family: MontserratSemiBold;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  .info-container .info-container__header {
    top: 1rem;
    line-height: 2rem;
  }
}

.info-container .button-container {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .info-container .button-container {
    bottom: -10rem;
  }
}

.info-container .button-container button {
  width: 240px;
}

.info-container .offeritemlist {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  .info-container .offeritemlist {
    display: none;
  }
}

.info-container .offeritem {
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 2rem;
}

.info-container .offeritem .offeritem__img {
  width: 90px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.info-container .offeritem .offeritem__title {
  text-align: center;
  color: #ffffff;
  font-family: MontserratSemiBold;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.info-container .offeritem .offeritem__subtitle {
  text-align: center;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.purple-button {
  border: none;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 0 25px rgba(2, 2, 2, 0.25), inset 0 -1px 0 1px rgba(2, 2, 2, 0.15);
  background-color: #883c97;
  background-image: linear-gradient(to top, rgba(2, 2, 2, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
  color: #ffffff;
  font-family: MontserratSemiBold;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}