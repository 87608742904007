.photo-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 2rem;
  @media #{$mobile} {
    justify-content: space-around;
  }
}

.photo {
  max-width: 380px;
  padding-bottom: 40px; 

  img {
    max-width: 100%;
  }
  .photo__description {
    color: $grey;
    font-family: $fontFamilySemiBold;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 1rem 0 0.5rem;
  }
  .photo__date {
    color: $greyActive;
    font-family: $fontFamily;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
  }
} 
