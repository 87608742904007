.team-description {
  max-width: 730px;
  color: $grey;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 1rem auto;
  text-align: center;

  @media #{$mobile} {
    margin: 1rem .5rem;
  }
}

.coach-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.coach {
  width: 270px;
  margin-bottom: 2rem;
}
.coach__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 230px;
    max-height: 230px;
    border-radius: 50%;
  }
}
.coach__name {
  text-align: center;
  color: $grey;
  font-family: $fontFamilySemiBold;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  margin: 1rem 0;
}
.coach__whos {
  .coach__who {
    text-align: center;
    color: $greyActive;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: .25rem 0;
  }
}