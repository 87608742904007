.news-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 2rem;

  @media #{$mobile} {
    justify-content: space-around;
  }
}

.news {
  max-width: 255px;
  background-color: $greyBack;
  margin-bottom: 2rem;

  img {
    max-width: 100%;
  }
  .news__container {
    padding: 1rem;
    overflow: hidden;
    height: 12rem;
  }
  .news__description { 
    color: $purple;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0.5rem;
  }
  .news__date {
    color: $greyActive;
    font-family: $fontFamily;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
} 