.news-container {
  margin: auto;
  margin-bottom: 4rem;
  max-width: 920px;

  h1.h1 {
    color: $grey;
    font-family: $fontFamilySemiBold;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
    padding: 0 1rem;
    margin: 0 0 2rem 0;
    text-align: center;
  }

  .date {
    margin: 2rem auto;
    text-align: center;
    height: 13px;
    color: $greyActive;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$mobile} {
      img {
        max-width: 100%;
      }
    }
  }

  p {
    color: $grey;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @media #{$mobile} {
      text-align: justify;
      padding: 0 .5rem;
    }
  }


  .social-share {
    color: $greySocial;
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;

    .social-share-icons {
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .social-share-icon {
        width: 34px;
        height: 34px;
        margin-left: 5px;
        margin-right: 5px;
        background-color: transparent;

        &.fb-icon {
          background: url(../img/ico_soc-FB.png) no-repeat;
        }
        &.vk-icon {
          background: url(../img/ico_soc-VK.png) no-repeat;
        }
        &.tw-icon {
          background: url(../img/ico_soc-TW.png) no-repeat;
        }
        &.google-icon {
          background: url(../img/ico_soc-G+.png) no-repeat;
        }
      }
    }
  }
}