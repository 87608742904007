.section {
  width: 100%;
}

.v-center {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  max-width: 1100px;
  margin: auto;
}

.header {
  background: $purple;
  height: 3.125rem;
  color: $white;

  @media #{$mobile} {
    height: 6rem;

    .container {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: space-around;
    }
  }

}


footer.section {
  background-color: $purpleFooter;
  color: $white;

  .footer-contacts {
    padding: 3rem 0 8rem;

    @media #{$mobile} {
      padding: 0 0 4rem;
      flex-direction: column;
    
      .left {
        display: flex;
        flex-direction:  column;
        align-items: center;
      }

      .social {
        align-items: center;
        margin-top: 1rem;
      }
    }
  }
}

.top-margined {
  margin-top: 1rem;
}
.top-margined-x2 {
  margin-top: 2rem;
}

.refs-parent {
  @media #{$mobile} {
    overflow: hidden;
  }
}

ul.refs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 40px 0;
  width: 100%;
  list-style: none;

  @media #{$mobile} {
    display: block;
    padding: 1rem 0;
    flex-wrap: nowrap;
    width: auto;
    overflow-x: scroll;
    list-style-type: none;
    white-space: nowrap;
    li {
      display: inline-block;
      a, button.btn-open-menu {
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }
  }

  li {
    a, button.btn-open-menu {
      display: block;
      white-space: nowrap;
      color: $white;
      text-transform: uppercase;
      text-decoration: none;
      margin-right: 1rem;
      font-size: 0.875rem;
      background: transparent;
      border: none;
      padding: 0;
      font-family: $fontFamily;
      cursor: pointer;

      &.active {
        color: $greyActive !important;
      }

      &.hover {
        color: $greyActive;
      }
    }

  }
}
button.btn-open-menu {
  &:focus {
    color: $greyActive !important;
    outline: none;
  }
}

.popup-menu-container {
  position: relative;
  z-index: 1;
}
.popup-menu-list {
  display: none;
  position: absolute;
  background-color: $greyBack;
  top: 2.5rem;
  left: -2rem;

  &::before, &::after {
    content: " ";
    position: absolute;
    display: block;
    width: 1rem;
    top: -1rem;
    height: 1rem;
  } 
  &::before {
    left: 50%;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid $greyBack;
  }
  &::after {
    right: 50%;    
    border-left: 1rem solid transparent;
    border-bottom: 1rem solid $greyBack;
  }
}

ul.refs li .popup-menu-container .popup-menu-list {
  a.popup-menu-item {
    margin: 0;
    color: $purple;
    font-family: $fontFamilySemiBold;
    padding: 1rem 2rem;
    display: block;
    text-align: center;

    &:hover {
      color: $grey;
      background-color: $white;
    }
  }
}
.bottom-bordered {
  border-bottom: 1px solid $white;
}

/* fonts */
.bold {
  font-weight: bold;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.bottom-margined {
  margin-bottom: 3.75rem;
}

a.btn-more,
button.btn-more,
.btn-more {
  width: 540px;
  max-width: 95%;
  text-align: center;
  border: 2px solid $greenLine;
  background-color: $greyBack;
  color:$grey;
  font-family: $fontFamilySemiBold;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 11px;
  cursor: pointer;
  &:focus {
    color: $grey;
  }
}

.logo {

  @media #{$mobile} {
    display: none;
  }

  text-transform: uppercase;
  padding-left: 4rem;
  height: 3rem;
  background: url(../img/logo.png) 0 50% no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo-place {
    font-weight: bold;
    font-size: 20px;
    color: $greenLogo;
  }

  .logo-work {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2.1px;
  }
}

.social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .social-refs {
    display: flex;
    
    .ref {
      width: 2.625rem;
      height: 2.625rem;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      &:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.cloud-ref {
        background: url(../img/cloud.png) 50% 50% no-repeat;
      }
      &.fb-ref {
        background: url(../img/fb.png) 50% 50% no-repeat;
      }
      &.vk-ref {
        background: url(../img/vk.png) 50% 50% no-repeat;
      }
      &.insta-ref {
        background: url(../img/inst.png) 50% 50% no-repeat;
      }
    }
  }
}
#club-list {
  max-width: 1089px;
  margin: auto;
  margin-bottom: 3rem;
  @media #{$mobile} {
    max-width: 100%;
  }

  .club-photo {
    @media #{$mobile} {
      display: flex;
      justify-content: center;
    }
  }
}

.slick-prev.slick-arrow,
.slick-next.slick-arrow {
  cursor: pointer;
  z-index: 100;
  content: " ";
  position: absolute;
  top: 53px;
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
}
.slick-prev.slick-arrow:focus,
.slick-next.slick-arrow:focus {
  outline: none;
}
.slick-prev.slick-arrow {
  left: -22px;
  background-image: url(../img/back.png);
  @media #{$mobile} {
    left: 1rem;
  }
}
.slick-next.slick-arrow {
  right: -3px;
  background-image: url(../img/forward.png);
  @media #{$mobile} {
    right: 1rem;
  }
}

.search-container {
  position: relative;
}

.submit-btn-search {

  border: none;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background: url(../img/ico-search.png) 100% 0 no-repeat;
  top: 0;
  right: 0;
}

.search-input {
  width: 250px;
  color: $white;
  font-family: $fontFamily;
  font-size: 14px;
  padding: 2px 18px 2px 0px;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  background-color: transparent;

  &::placeholder {
    color: $white;
  }

  &:focus {
    outline: none;
  }
}


.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;
  margin: auto;
  margin-top: 116px;
  width: 770px;
  padding: 40px;
  background-color: white;

  @media #{$mobile} {
    bottom: auto;
    margin: 0;
    width: 100%;
    border-radius: 0;
    padding: 0;
    min-height: 100%;
    position: absolute;

    .modal-container {
      padding: 40px 1rem;
    }
  }

  .purple-button {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.btn-modal-close {
  background: none;
  border: none;
  font-size: 3rem;
  font-family: $fontFamily;
  line-height: 3rem;
  position: absolute;
  top: .2rem;
  right: .5rem;
  color: $greyActive;
  cursor: pointer;
}

.modal-header {
  color: $grey;
  font-family: $fontFamilySemiBold;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 1rem;
  margin: 0;


  @media #{$mobile} {
    margin-top: 1rem;
  }
}

.modal-result {
  padding: 2rem 1rem;
  margin-top: 1rem;

  p {
    color: $grey;
    font-family: $fontFamily;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    margin: .5rem;
  }
}

.modal-form {
  margin-top: 1rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  padding: 0 2rem;
  @media #{$mobile} {
    padding: 0;
  }
}

.inputs-row {
  display: flex;
  margin-top: 1rem;


  @media #{$mobile} {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}

.inputs-column {
}

.input-title {
  color: $grey;
  font-family: $fontFamilySemiBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.input {
  min-width: 248px;
  padding: 0.5rem;
  font-family: $fontFamily;
  font-size: 16px;
  border: 1px solid #ccc;


  &-name {
    min-width: 360px;
    margin-right: 2rem;

    @media #{$mobile} {
      min-width: auto;
      margin-right: 0;
    }
  }
}

select.input {
  background-color: white;
}